<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Employee Tuberculin Skin Test (TST) and Evaluation
                <br>
                <small>Confidential Employee Medical Information</small>
              </b-card-title>

              <validation-observer ref="simpleRules">
                <b-form>

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <strong>Instructions:</strong> Tuberculosis (TB) screening must be performed by a licensed health care provider whose legally authorized scope of practice allows him/her to conduct medical examinations and/or the Mantoux TB Skin Test (TST) in accordance with the recommendations of the Centers for Disease Control and Prevention to determine if a person has TB infection or disease.

                  <h4 class="text-center mt-2 mb-2">
                    1. Employee Information
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* First Name"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            v-model="fname"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="mi"
                      >
                        <label
                          class="sr-only"
                          for="mi"
                        >
                          Middle Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="mi"
                        >
                          <b-form-input
                            id="mi"
                            v-model="mi"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Middle Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="lname"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* Gender"
                        label-for="gender"
                      >
                        <label
                          class="sr-only"
                          for="gender"
                        >
                          * Gender
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Gender"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="gender"
                            name="gender"
                          >
                            <b-form-radio
                              v-for="option in radioGender"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Birth Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Birth Date"
                          label-for="date-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-birth"
                          >
                            * Birth Date
                          </label>
                          <flat-pickr
                            id="date-birth"
                            v-model="birthdate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Personnel # (If Known)"
                        label-for="personnel-number"
                      >
                        <label
                          class="sr-only"
                          for="personnel-number"
                        >
                          Personnel # (If Known)
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="personnel-number"
                            v-model="personnelNum"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="1234 567 8900"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* New Employee/Cadet"
                        label-for="new-emp"
                      >
                        <label
                          class="sr-only"
                          for="new-emp"
                        >
                          * New Employee/Cadet
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="New Employee/Cadet"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="newEmp"
                            v-model="form.newEmp"
                            name="new-emp"
                            title="New Employee/Cadet"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Institution or Division"
                        label-for="institution"
                      >
                        <label
                          class="sr-only"
                          for="institution"
                        >
                          * Institution or Division
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Institution or Division"
                          rules="required"
                        >
                          <b-form-input
                            id="institution"
                            ref="institutionDivision"
                            v-model="form.institutionDivision"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Institution or Division"
                            title="Institution or Division"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Unit or Branch"
                        label-for="unit"
                      >
                        <label
                          class="sr-only"
                          for="unit"
                        >
                          * Unit or Branch
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Unit or Branch"
                          rules="required"
                        >
                          <b-form-input
                            id="unit"
                            ref="unit"
                            v-model="form.unit"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Unit or Branch"
                            title="Unit or Branch"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Department (If not CDCR)"
                        label-for="dept"
                      >
                        <label
                          class="sr-only"
                          for="dept"
                        >
                          Department (If not CDCR)
                        </label>
                        <b-form-input
                          id="dept"
                          ref="dept"
                          v-model="form.dept"
                          placeholder="Department (If not CDCR)"
                          title="Department"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Employee Signature"
                        label-for="emp-signature"
                      >
                        <label
                          class="sr-only"
                          for="emp-signature"
                        >
                          * Employee Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="sec1signature"
                            v-model="form.sec1signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.sec1signature === ''}"
                            title="Employee Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.sec1signature === ''"
                                class="text-danger"
                              >{{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignatureSec1"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignatureSec1"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Employee Date Signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="emp-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="emp-date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="emp-date"
                            ref="sec1dateSigned"
                            v-model="form.sec1dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Employee Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2">
                    2. Health Care Provider
                  </h4>
                  <p class="text-center">
                    <small><em>(Complete Sections 2-6 as required - refer to the instructions below)</em></small>
                  </p>

                  <p class="text-center">
                    Prior TST / TB Blood Test / TB History<br>(As Documented in the Employee Health Care Record)<br>NOTE: Private Providers attach Documentation of Prior History
                  </p>

                  <b-row
                    class="mt-2"
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Prior Positive TB Test/Infection?"
                        label-for="test-infection"
                      >
                        <label
                          class="sr-only"
                          for="test-infection"
                        >
                          * Prior Positive TB Test/Infection?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Prior Positive TB Test/Infection?"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="testInfection"
                            v-model="form.testInfection"
                            name="test-infection"
                            title="Prior Positive TB Test/Infection?"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                              @change="disabledTBInfeDate(option.id)"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="If yes, Date"
                        label-for="sec2-date"
                      >
                        <label
                          class="sr-only"
                          for="sec2-date"
                        >
                          If yes, Date
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="sec2-date"
                            ref="testDate"
                            v-model="form.testDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Prior Positive TB Test/Infection? Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Induration"
                        label-for="induration"
                      >
                        <label
                          class="sr-only"
                          for="induration"
                        >
                          * Induration
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Induration"
                          rules="required"
                        >
                          <b-form-input
                            id="induration"
                            ref="induration"
                            v-model="form.induration"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Induration"
                            title="Induration"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="* TB Blood Test"
                        label-for="blood-test"
                      >
                        <label
                          class="sr-only"
                          for="blood-test"
                        >
                          * TB Blood Test
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="TB Blood Test"
                          rules="required"
                        >
                          <b-form-input
                            id="blood-test"
                            ref="bloodTest"
                            v-model="form.bloodTest"
                            :state="errors.length > 0 ? false : null"
                            placeholder="TB Blood Test"
                            title="TB Blood Test"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Prior TB Disease?"
                        label-for="disease"
                      >
                        <label
                          class="sr-only"
                          for="disease"
                        >
                          * Prior TB Disease?
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Prior TB Disease?"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="disease"
                            v-model="form.disease"
                            name="disease"
                            title="Prior TB Disease?"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                              @change="disabledTBDisDate(option.id)"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="If yes, Date"
                        label-for="sec2-diseaseDate"
                      >
                        <label
                          class="sr-only"
                          for="sec2-diseaseDate"
                        >
                          If yes, Date
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="sec2-diseaseDate"
                            ref="diseaseDate"
                            v-model="form.diseaseDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Prior TB Disease? Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <em>NOTICE: HIV and other Medical Conditions may cause a TST to be Negative when TB Infection is Present</em>

                  <h4 class="text-center mt-3">
                    3. TST Administration (5TU / 0.1 milliliter) / Blood Test
                  </h4>

                  <b-row
                    class="mt-2"
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* Result"
                        label-for="testRes"
                      >
                        <label
                          class="sr-only"
                          for="testRes"
                        >
                          * Result
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Result"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="testRes"
                            v-model="form.testRes"
                            name="testRes"
                            title="Result"
                          >
                            <b-form-radio
                              v-for="option in radioTSTRes"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* TB Blood Test Lot #"
                        label-for="testLot"
                      >
                        <label
                          class="sr-only"
                          for="testLot"
                        >
                          * TB Blood Test Lot #
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="TB Blood Test Lot #"
                          rules="required"
                        >
                          <b-form-input
                            id="testLot"
                            ref="testLot"
                            v-model="form.testLot"
                            :state="errors.length > 0 ? false : null"
                            placeholder="TB Blood Test Lot #"
                            title="TB Blood Test Lot #"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Expiration Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Expiration Date"
                          label-for="expDate"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="expDate"
                          >
                            * Expiration Date
                          </label>
                          <flat-pickr
                            id="expDate"
                            ref="expDate"
                            v-model="form.expDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Expiration Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* TST Administered By"
                        label-for="tstAdmin"
                      >
                        <label
                          class="sr-only"
                          for="tstAdmin"
                        >
                          * TST Administered By
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="TST Administered By"
                          rules="required"
                        >
                          <b-form-input
                            id="tstAdmin"
                            ref="tstAdmin"
                            v-model="form.tstAdmin"
                            :state="errors.length > 0 ? false : null"
                            placeholder="TST Administered By"
                            title="TST Administered By"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Signature"
                        label-for="admin-signature"
                      >
                        <label
                          class="sr-only"
                          for="admin-signature"
                        >
                          * Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="TST Administered Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="sec3signature"
                            v-model="form.sec3signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.sec3signature === ''}"
                            title="TST Administered Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.sec3signature === ''"
                                class="text-danger"
                              >{{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignatureSec3"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignatureSec3"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Administered Date Signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="admin-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="admin-date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="admin-date"
                            ref="sec3dateSigned"
                            v-model="form.sec3dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Administered Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Injection Site"
                        label-for="injectionSite"
                      >
                        <label
                          class="sr-only"
                          for="injectionSite"
                        >
                          * Injection Site
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Injection Site"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="injectionSite"
                            v-model="form.injectionSite"
                            name="injectionSite"
                            title="Injection Site"
                          >
                            <b-form-radio
                              v-for="option in radioInjectionSite"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Injection / Blood Draw Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Injection / Blood Draw Date"
                          label-for="bloodDrawDate"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="bloodDrawDate"
                          >
                            * Injection / Blood Draw Date
                          </label>
                          <flat-pickr
                            id="bloodDrawDate"
                            ref="bloodDrawDate"
                            v-model="form.bloodDrawDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Injection / Blood Draw Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Interpretation"
                        label-for="interpretation"
                      >
                        <label
                          class="sr-only"
                          for="interpretation"
                        >
                          * Interpretation
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Interpretation"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="interpretation"
                            v-model="form.interpretation"
                            name="interpretation"
                            title="Interpretation"
                          >
                            <b-form-radio
                              v-for="option in radioInterpretation"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* MM Induration"
                        label-for="tstResInduration"
                      >
                        <label
                          class="sr-only"
                          for="tstResInduration"
                        >
                          * MM Induration
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="TST / Blood Test / Result MM Induration"
                          rules="required"
                        >
                          <b-form-input
                            id="tstResInduration"
                            ref="tstResInduration"
                            v-model="form.tstResInduration"
                            :state="errors.length > 0 ? false : null"
                            placeholder="MM Induration"
                            title="MM Induration"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Blood Test Units"
                        label-for="tstResUnits"
                      >
                        <label
                          class="sr-only"
                          for="tstResUnits"
                        >
                          * Blood Test Units
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="TST / Blood Test / Result Blood Test Units"
                          rules="required"
                        >
                          <b-form-input
                            id="tstResUnits"
                            ref="tstResUnits"
                            v-model="form.tstResUnits"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Blood Test Units"
                            title="Blood Test Units"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Results"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date Results"
                          label-for="dateRes"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="dateRes"
                          >
                            * Date Results
                          </label>
                          <flat-pickr
                            id="dateRes"
                            ref="dateRes"
                            v-model="form.dateRes"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Results"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Sign and Symptom Eval"
                        label-for="sign-eval"
                      >
                        <label
                          class="sr-only"
                          for="sign-eval"
                        >
                          * Sign and Symptom Eval
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Sign and Symptom Eval"
                          rules="required"
                        >
                          <b-form-input
                            id="sign-eval"
                            ref="signEval"
                            v-model="form.signEval"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Sign and Symptom Eval"
                            title="Sign and Symptom Eval"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2">
                    4. Evaluation for Signs and Symptoms
                  </h4>
                  <p class="text-center">
                    <small><em>(Must be Completed For All Individuals)</em></small>
                  </p>

                  <b-row
                    class="mt-2"
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Symptoms (Check All that Apply)"
                        label-for="symptoms"
                      >
                        <label
                          class="sr-only"
                          for="symptoms"
                        >
                          * Symptoms (Check All that Apply)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Symptoms"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="symptoms"
                            v-model="form.symptoms"
                            :options="['No Symptoms', 'Persistent ( > 2 wks ) Cough', 'Weight Loss (Unexplained)', 'Unexplained Fever', 'Unexplained Fatigue', 'Unexplained Night Sweats']"
                            :state="errors.length > 0 ? false : null"
                            title="Symptoms"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2">
                    5. Chest X-Ray
                  </h4>

                  <b-row
                    class="mt-2"
                  >
                    <b-col
                      cols="12"
                      lg="5"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Requirement"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="xray"
                            v-model="form.xray"
                            name="xray"
                            title="Chest X-Ray"
                          >
                            <b-form-radio
                              v-for="option in radioXray"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                              @change="disabledXrayFile(option.id)"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Chest X-Ray Result"
                        label-for="xrayRes"
                      >
                        <label
                          class="sr-only"
                          for="xrayRes"
                        >
                          * Chest X-Ray Result
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Chest X-Ray Result"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="xrayRes"
                            v-model="form.xrayRes"
                            name="xrayRes"
                            title="Chest X-Ray Result"
                          >
                            <b-form-radio
                              v-for="option in radioXrayRes"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* Consistent w/ TB"
                        label-for="consistent"
                      >
                        <label
                          class="sr-only"
                          for="consistent"
                        >
                          * Consistent w/ TB
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Consistent w/ TB"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="consistent"
                            v-model="form.consistent"
                            name="consistent"
                            title="Consistent w/ TB"
                          >
                            <b-form-radio
                              v-for="option in radioYN"
                              :key="option.text"
                              :value="option.text"
                              :state="errors.length > 0 ? false : null"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      lg="8"
                      cols="12"
                    >

                      <b-form-group
                        label="X-Ray Copy"
                        label-for="xrayFile"
                      >
                        <label
                          class="sr-only"
                          for="xrayFile"
                        >
                          X-Ray Copy
                        </label>
                        <b-form-file
                          ref="xrayFile"
                          v-model="form.xrayFile"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          type="file"
                          accept="image/*"
                          multiple
                          capture
                          title="X-Ray Copy"
                        />
                      </b-form-group>

                      <b-list-group v-if="xrayFileFile">

                        <b-list-group-item
                          v-for="(item, index) in xrayFileFile"
                          :key="item"
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${item}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ item }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-${index}-xray-file-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: item })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-${index}-xray-file-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-${index}-xray-file-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, xrayFile: item })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-${index}-xray-file-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2">
                    6. Comments
                  </h4>

                  <b-row
                    class="mt-2"
                  >
                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Comments"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="comments"
                            v-model="form.comments"
                            :options="['Employee Referred for Follow-Up Medical Evaluation', 'Employee Provided Written Notification of TST Results', 'No Show Employee Notified']"
                            :state="errors.length > 0 ? false : null"
                            title="Comments"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <hr>

                  <b-row
                    class="mt-3 mb-2 text-center"
                  >
                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Test Result"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="empTBRes"
                            v-model="form.empTBRes"
                            :options="['Employee is Free of Infectious Tuberculosis']"
                            :state="errors.length > 0 ? false : null"
                            title="Test Result"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <hr>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Licensed Evaluator Name"
                        label-for="evalName"
                      >
                        <label
                          class="sr-only"
                          for="evalName"
                        >
                          * Licensed Evaluator Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Licensed Evaluator Name"
                          rules="required"
                        >
                          <b-form-input
                            id="evalName"
                            ref="evalName"
                            v-model="form.evalName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Licensed Evaluator Name"
                            title="Licensed Evaluator Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Licensed Number"
                        label-for="licenseNum"
                      >
                        <label
                          class="sr-only"
                          for="licenseNum"
                        >
                          * Licensed Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Licensed Number"
                          rules="required"
                        >
                          <b-form-input
                            id="licenseNum"
                            ref="licenseNum"
                            v-model="form.licenseNum"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Licensed Number"
                            title="Licensed Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Licensed Evaluator Signature"
                        label-for="evalSignature"
                      >
                        <label
                          class="sr-only"
                          for="evalSignature"
                        >
                          * Licensed Evaluator Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Licensed Evaluator Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="evalSignature"
                            v-model="form.evalSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.evalSignature === ''}"
                            title="Licensed Evaluator Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.evalSignature === ''"
                                class="text-danger"
                              >{{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignatureEval"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignatureEval"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Evaluator Date Signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="evalDateSigned"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="evalDateSigned"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="evalDateSigned"
                            ref="sec6dateSigned"
                            v-model="form.sec6dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Evaluator Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <h4 class="mt-2">
                    Employee Tuberculin Skin Test (TST) and Evaluation
                  </h4>
                  <p>
                    <small><em>CDCR 7336 (Rev. 07/15) </em></small>
                  </p>

                  <p class="text-center">
                    <strong>NOTICE TO PRIVATE PHYSICIANS<br>CONFIDENTIAL EMPLOYMENT MEDICAL INFORMATION</strong>
                  </p>

                  THE CALIFORNIA PENAL CODE, SECTION 6006 et seq., REQUIRES ALL CALIFORNIA DEPARTMENT OF CORRECTIONS AND REHABILITATION (CDCR) employees and certain other individuals to have an initial, annual, and as medically necessary Mantoux Tuberculin Skin Test (TST) or evaluation. The testing must occur as instructed below. The employee must provide the results of the TST and/or evaluation on the REQUIRED form: the Employee Annual Tuberculin Skin Test (TST) and Evaluation, CDCR 7336.<br><br>
                  DEFINITIONS:<br>
                  INDURATION: Swelling or raised skin. Note: the presence of erythema is NOT indicative of a TST reaction; only the induration is measured.<br>
                  MANTOUX TST: Intradermal injection of 0.1 milliliters (ml) of Purified Protein Derivative, 5 Tuberculin Units (TU).PRIOR TST: A Mantoux TST in which clearly documented and dated results are available in millimeters (mm).<br>
                  NEGATIVE TST RESULT: Induration of less than (&lt;) 10 mm if new, or &lt; 5 mm, if contact or known immunocompromised.<br>
                  POSITIVE TST RESULT: Induration equal to or greater than (>) 10 mm, OR > 5 mm if contact or known immunocompromised.<br>
                  INSTRUCTIONS: EMPLOYEE<br><br>

                  1. Complete all of the items in <strong>SECTION 1</strong> - All Boxes Must Be Completely Filled In.
                  <ul>
                    <li>Be sure the information you provide is accurate and complete.</li>
                    <li>The health care provider(s) (HCP) administering and evaluating the TST, including the exam for TB signs and symptoms, must sign and date the appropriate blocks.</li>
                    <li>Advise the HCP to follow the steps below when completing SECTION 2 through SECTION 6.</li>
                    <li>If a chest x-ray (CXR) is needed, you must submit a copy of the CXR report with this form to be placed in your health record.</li>
                    <li>Submit the completed form (Employee Tuberculin Skin Test (TST) and Evaluation, CDCR 7336), in a sealed envelope, as instructed by your supervisor/TB coordinator.</li>
                  </ul>

                  INSTRUCTIONS: HEALTH CARE PROVIDER - All Boxes Must Be Completely Filled In.<br>
                  <strong>SECTION 2</strong>: If prior test TST results are available, the employee or HCP must provide written documentation including the patient’s name, date test was administered, and reaction in mm or IU. Document this in SECTION 2. If documented results are:
                  <ul>
                    <li>NEGATIVE and more than 30 days old, proceed to Section 3</li>
                    <li>NEGATIVE and less than 30 days old, proceed to Section 4.</li>
                    <li>POSITIVE on any date: proceed to Section 4. Must also complete Section 5.</li>
                  </ul>

                  If there are no appropriately documented prior TST or TB blood test results, go to the instructions for Section #3.<br><br>

                  <strong>SECTION 3</strong>: Administer a new TST or TB blood test, and document results in SECTION 3. NOTE: The HCP administering the TST (SECTION 3), and the HCP evaluating the TST (SECTION 6), must sign in the appropriate blocks. If the TST or TB blood test results are:
                  <ul>
                    <li>NEGATIVE, complete Section 4. Evaluator must sign and date under Section 6.</li>
                    <li>POSITIVE, proceed to Section 4. Must also complete Section 5. Evaluator must sign and date under Section 6.</li>
                  </ul>

                  If an individual claims to have a prior positive TB testST, but cannot provide appropriate documentation, a TST or TB blood test must still be administered.<br>
                  This is not medically contraindicated. However, if there are still questions, although this is not a CDCR procedure, it has been found useful to administer a diluted TST: dilute 0.2 cc of the standard 5 TU/0.1cc solution with 0.8 cc of sterile saline, then use 0.1 of this solution to administer a TST. If the results are positive, no further testing is necessary, proceed as directed below for positive TST’s. If the results are negative, proceed with a standard TST.<br>
                  If the administered or documented TST or TB blood testshows a NEGATIVE result, the employee probably does not have TB infection. Factors affecting the immune system, pregnancy, or recent TB infection may cause a false negative TST or TB blood test reaction, even when TB disease exists, but

                  <p class="text-center">
                    <strong>CDCR HCPs CANNOT ASK CDCR EMPLOYEES ABOUT NON TB HEALTH HISTORY, INCLUDING IMMUNOSUPPRESSIVE CONDITIONS</strong>
                  </p>

                  If the TB test TST indicates a POSITIVE reaction, further medical evaluation and a CXR are needed to rule out active TB disease.
                  <ul>
                    <li>Complete SECTIONS 4, 5 AND 6. The HCP evaluating for TB signs and symptoms, must sign and date the form in the space provided at the bottom of the form (SECTION 6).</li>
                    <li>Give a copy of the CXR report, if a CXR is taken, to the employee for the CDCR records.<br>The space identified as “DATE TST READ OR OF SIGNS & SYMPTOMS EXAM” refers to date that the employee’s TB status is determined.</li>
                    <li>After evaluation and/or treatment the CDCR 7336 is completed</li>
                    <li>Give the completed CDCR 7336 and the CXR report to the employee.</li>
                  </ul>

                  <strong>SECTION 4</strong>: Complete evaluation for all employees, regardless of TB testTST result, for TB signs and symptoms; 3 or more positives warrant special concern.<br><br>
                  <strong>SECTION 5</strong>: To be completed for individuals with a documented prior or newly significant TST or TB blood test. Attach copy of CXR report.<br><br>
                  <strong>SECTION 6</strong> Comments as necessary. Evaluator (Physician and Surgeon or a licensed designee) must sign and date the form.<br><br>

                  The Centers for Disease Control and Prevention and the California Tuberculosis Controllers Association recommend the following:
                  <ol>
                    <li>Tine test is NOT an acceptable skin test to determine exposure to the TB bacillus.</li>
                    <li>CXR is an unacceptable screening method for detecting TB infection.</li>
                    <li>The only acceptable screening method(s) for detecting TB infection are TB screening tests that are licensed by the Federal Food and Drug Administration (FDA) and recommended by the Centers for Disease Control (CDC).</li>
                    <li>
                      The process for administering, evaluation, and documenting the Mantoux TST are:
                      <ol type="a">
                        <li>Must be given intradermally.</li>
                        <li>0.1 ml (s) of 5 TU Purified Protein Derivative must be used.</li>
                        <li>The test must be interpreted by a qualified HCP.</li>
                        <li> Results must be documented/reported in mm(s) of induration.</li>
                      </ol>
                    </li>
                  </ol>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>

                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form11', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form13', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroupPrepend, BInputGroupAppend, BInputGroup, VBToggle, BFormFile, BFormRadioGroup, BFormRadio, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { VueSignaturePad } from 'vue-signature-pad'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mask } from 'vue-the-mask'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { mapState } from 'vuex'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormFile,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    VueSignaturePad,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const fname = ref('')
    const mi = ref('')
    const lname = ref('')
    const gender = ref('')
    const birthdate = ref('')
    const personnelNum = ref('')
    const sec1signature = ref(null)
    const sec3signature = ref(null)
    const evalSignature = ref(null)

    const xrayFileFile = ref(null)

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          fname.value = jsonObjectLiterals.data.firstName
          mi.value = jsonObjectLiterals.data.middleName
          lname.value = jsonObjectLiterals.data.lastName
          gender.value = jsonObjectLiterals.data.sex
          birthdate.value = jsonObjectLiterals.data.dateOfBirth
          personnelNum.value = jsonObjectLiterals.data.mobileNumber

          form.value = jsonObjectLiterals.data

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm12', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data
                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }
                form.value.cid = router.currentRoute.query.id
                if (jsonObjectLiterals.data.sec1signature) {
                  sec1signature.value.fromDataURL(jsonObjectLiterals.data.sec1signature)
                }
                if (jsonObjectLiterals.data.sec3signature) {
                  sec3signature.value.fromDataURL(jsonObjectLiterals.data.sec3signature)
                }
                if (jsonObjectLiterals.data.evalSignature) {
                  evalSignature.value.fromDataURL(jsonObjectLiterals.data.evalSignature)
                }

                xrayFileFile.value = jsonObjectLiterals.data.xrayFile

                if (form.value.id) {
                  form.value.form = 'folder3_form12'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
      wrap: true,
      allowInput: true,
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      fname,
      mi,
      lname,
      gender,
      birthdate,
      personnelNum,
      sec1signature,
      sec3signature,
      evalSignature,

      xrayFileFile,

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,

      // options
      radioGender: [
        {
          id: 1,
          text: 'Male',
        },
        {
          id: 2,
          text: 'Female',
        },
      ],
      radioYN: [
        {
          id: 1,
          text: 'Yes',
        },
        {
          id: 2,
          text: 'No',
        },
      ],
      radioTSTRes: [
        {
          id: 1,
          text: 'Tubersol',
        },
        {
          id: 2,
          text: 'Aplisol',
        },
      ],
      radioInjectionSite: [
        {
          id: 1,
          text: 'LFA * (Left Forearm)',
        },
        {
          id: 2,
          text: 'RFA ** (Right Forearm)',
        },
      ],
      radioInterpretation: [
        {
          id: 1,
          text: 'Positive',
        },
        {
          id: 2,
          text: 'Negative',
        },
      ],
      radioXray: [
        {
          id: 1,
          text: 'Chest X-Ray Needed',
        },
        {
          id: 2,
          text: 'Chest X-Ray Report on File (Copy Required)',
        },
      ],
      radioXrayRes: [
        {
          id: 1,
          text: 'Normal',
        },
        {
          id: 2,
          text: 'Abnormal',
        },
      ],

      // static val
      // tbInfectionDate: true,
      // tbDiseaseDate: true,
      // xrayCopyFile: 1,
    }
  },
  computed: {
    ...mapState('app-hr', ['uploadPercentage']),
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.sec1signature) {
            self.$refs.sec1signature.clearSignature()
            self.$refs.sec1signature.fromDataURL(jsonObjectLiterals.data.sec1signature)
          }
          if (jsonObjectLiterals.data.sec3signature) {
            self.$refs.sec3signature.clearSignature()
            self.$refs.sec3signature.fromDataURL(jsonObjectLiterals.data.sec3signature)
          }
          if (jsonObjectLiterals.data.evalSignature) {
            self.$refs.evalSignature.clearSignature()
            self.$refs.evalSignature.fromDataURL(jsonObjectLiterals.data.evalSignature)
          }

          self.xrayFileFile = jsonObjectLiterals.data.xrayFile

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* this.saveSec1signature()
      this.saveSec3signature()
      this.saveEvalSignature() */
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm12' : 'app-hr/updateForm12', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    /* saveSec1signature() {
      // eslint-disable-next-line
      const { isEmpty, data } = this.$refs.sec1signature.saveSignature()
      // eslint-disable-next-line
      this.form.sec1signature = !isEmpty ? data : ''
    },
    saveSec3signature() {
      // eslint-disable-next-line
      const { isEmpty, data } = this.$refs.sec3signature.saveSignature()
      // eslint-disable-next-line
      this.form.sec3signature = !isEmpty ? data : ''
    },
    saveEvalSignature() {
      // eslint-disable-next-line
      const { isEmpty, data } = this.$refs.evalSignature.saveSignature()
      // eslint-disable-next-line
      this.form.evalSignature = !isEmpty ? data : ''
    }, */
    clearSignatureSec1() {
      this.$refs.sec1signature.clearSignature()
    },
    undoSignatureSec1() {
      this.$refs.sec1signature.undoSignature()
    },
    clearSignatureSec3() {
      this.$refs.sec3signature.clearSignature()
    },
    undoSignatureSec3() {
      this.$refs.sec3signature.undoSignature()
    },
    clearSignatureEval() {
      this.$refs.evalSignature.clearSignature()
    },
    undoSignatureEval() {
      this.$refs.evalSignature.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      let object = { isEmpty: null, data: null }

      object = this.$refs.sec1signature.saveSignature()
      this.form.sec1signature = !object.isEmpty ? object.data : ''

      object = this.$refs.sec3signature.saveSignature()
      this.form.sec3signature = !object.isEmpty ? object.data : ''

      object = this.$refs.evalSignature.saveSignature()
      this.form.evalSignature = !object.isEmpty ? object.data : ''
    },
    previewFormFile(params) {
      Swal.fire({
        text: params.item,
        padding: '1em 3em',
        // eslint-disable-next-line
        imageUrl: this.$urlUpload + params.id + '/' + params.item,
        imageAlt: params.item,
        showConfirmButton: false,
        showCloseButton: true,
        returnFocus: false,
      })
    },
    deleteFormFile(params) {
      const self = this

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        // eslint-disable-next-line
        text: 'Do you want to delete ' + Object.values(params)[1] + '?',
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-hr/deleteForm12File', params)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              // console.log(jsonObjectLiterals)

              // update object file
              const key = Object.keys(params)[1]
              self.form[key] = jsonObjectLiterals.data[key]
              self[`${key}File`] = jsonObjectLiterals.data[key]

              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                // eslint-disable-next-line
                text: 'Deleted succesfully.',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch(error => {
              console.error(error)

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        }
      })
    },
    // disabledTBInfeDate(id) {
    //   if (id === 2) {
    //     this.tbInfectionDate = true
    //     this.form.testDate = ''
    //   } else {
    //     this.tbInfectionDate = false
    //   }
    // },
    // disabledTBDisDate(id) {
    //   if (id === 2) {
    //     this.tbDiseaseDate = true
    //     this.form.diseaseDate = ''
    //   } else {
    //     this.tbDiseaseDate = false
    //   }
    // },
    // disabledXrayFile(id) {
    //   if (id === 1) {
    //     this.xrayCopyFile = 1
    //     this.form.xrayFile = []
    //   } else {
    //     this.xrayCopyFile = 0
    //   }
    // },
  },
}
</script>

<style scoped>
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
